@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=block');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  html {
    height: 100%;
    overflow: hidden;
    touch-action: manipulation;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

:root {
  --primary-color: #2CC985;
  --link-color: #2CC985;
  --success-color: #28A745;
  --warning-color: #FFC107;
  --error-color: #DC3545;
  --font-family: 'IBM Plex Sans', sans-serif;
  --heading-color: #343A40;
  --text-color: #343A40;
  --background-color: #111827;
  --border-radius-base: 4px;
  --font-smoothing: antialiased;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  touch-action: manipulation;
  font-smooth: always;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  height: 100%;
  overflow: auto;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
  background-color: var(--background-color);
  color: var(--text-color);
}

input:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

input, textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

* {
  -webkit-overflow-scrolling: touch;
}
